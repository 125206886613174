import React from 'react';
import { useTranslation } from 'react-i18next';
import './specialties.scss';
import { motion } from 'framer-motion';

interface Specialty {
  id: string;
  name: string;
  icon: string;
  description: string;
}

interface SpecialtiesProps {
  specialties: Specialty[];
}

const Specialties: React.FC<SpecialtiesProps> = ({ specialties }) => {
  const { t } = useTranslation();

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  return (
    <div className="specialties-container">
      <motion.div 
        className="specialties-row"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          {t('specialties.title')}
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.3 }}
        >
          {t('specialties.subtitle')}
        </motion.p>
      </motion.div>

      <motion.div 
        className='specialties-row'
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <div className="specialties-list">
          {specialties.map((specialty, index) => (
            <motion.div 
              key={specialty.id} 
              className="specialties__col"
              variants={itemVariants}
              whileHover={{ 
                scale: 1.03,
                transition: { duration: 0.2 }
              }}
            >
              <motion.div 
                className="specialty text-center"
                whileHover={{
                  boxShadow: "0 8px 30px rgba(0,0,0,0.12)"
                }}
                transition={{ duration: 0.3 }}
              >
                <div className="specialty__content">
                  <motion.h2 
                    className="specialty__name"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.2 + index * 0.1 }}
                  >
                    {t(`specialties.${specialty.id}.name`)}
                  </motion.h2>
                  <div className="specialty__icon">
                    <img src={specialty.icon} alt={t(`specialties.${specialty.id}.name`)} />
                  </div>
                  <motion.p 
                    className="specialty__description"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: 0.3 + index * 0.1 }}
                  >
                    {t(`specialties.${specialty.id}.description`)}
                  </motion.p>
                </div>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default Specialties;