import React, { useMemo } from 'react';
import './updates-section.scss';
import Banner from './Banner';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

function UpdatesSection() {
    const { t, i18n } = useTranslation();

    const notices = useMemo(() => [
        {
            title: t('updates.notice.new-timetable.title'),
            date: new Date('2024-11-14').toLocaleDateString(i18n.language, {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
            }),
            message: t('updates.notice.new-timetable.message')
        },
    ], [t, i18n.language]);

    return (
        <motion.section 
            className="updates-section" 
            id='updates'
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true, margin: "-100px" }}
            transition={{ duration: 0.6 }}
        >
            <div className="container updates-container">
                <motion.div 
                    className='updates-row'
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, margin: "-100px" }}
                    transition={{ duration: 0.6, delay: 0.2 }}
                >
                    <div className='col-md-8'>
                        <motion.h2 
                            className='updates-title'
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true, margin: "-100px" }}
                            transition={{ duration: 0.6, delay: 0.3 }}
                        >
                            {t('updates.title')}
                        </motion.h2>
                    </div>
                </motion.div>
                <motion.div 
                    className='updates-row'
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, margin: "-100px" }}
                    transition={{ duration: 0.6, delay: 0.4 }}
                >
                    <Banner notices={notices}/>
                </motion.div>
            </div>
        </motion.section>
    );
}

export default UpdatesSection;