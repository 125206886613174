import React from "react";
import { useTranslation } from "react-i18next";
import "./find-us-section.scss";
import { motion } from "framer-motion";

interface TimetableEntry {
  day: string;
  hours: string;
}

function FindUsSection() {
  const { t } = useTranslation();
  const timetables: TimetableEntry[] = [
    { day: 'Monday', hours: '09:00 - 12:00, 13:30 - 16:30' },
    { day: 'Tuesday', hours: '09:00 - 12:00, 13:30 - 16:30' },
    { day: 'Wednesday', hours: '09:00 - 12:00, 13:30 - 16:30' },
    { day: 'Thursday', hours: '09:00 - 12:00' },
    { day: 'Friday', hours: '-' },
    { day: 'Saturday', hours: '-' },
    { day: 'Sunday', hours: '-' },
  ];
  
  return (
    <section className="find-us" id="find">
      <motion.div 
        className="container find-us-container"
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        <motion.div 
          className="find-us-info"
          initial={{ opacity: 0, x: -20 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <motion.h2
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.3 }}
          >
            {t("findUs.title")}
          </motion.h2>
          <motion.div 
            className="contact-detail"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <strong>{t("findUs.addressLabel")}</strong>
            <div>{t("findUs.address")}</div>
          </motion.div>
          <motion.div 
            className="contact-detail"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.5 }}
          >
            <strong>{t("findUs.phoneLabel")}</strong>
            <div>
              <a href="tel:+4122990255" className="phone-link">
                {t("findUs.phone")}
              </a>
            </div>
          </motion.div>
        </motion.div>

        <motion.div 
          className="find-us-timetables"
          initial={{ opacity: 0, x: 20 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <motion.h3
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.3 }}
          >
            {t("findUs.timetableTitle")}
          </motion.h3>
          <motion.table 
            className="timetable"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <thead>
              <tr>
                <th>{t("findUs.day")}</th>
                <th>{t("findUs.hours")}</th>
              </tr>
            </thead>
            <tbody>
              {timetables.map((entry, index) => (
                <motion.tr 
                  key={index}
                  initial={{ opacity: 0, y: 10 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.3, delay: 0.5 + index * 0.1 }}
                >
                  <td>{t(`findUs.days.${entry.day.toLowerCase()}`)}</td>
                  <td>{entry.hours}</td>
                </motion.tr>
              ))}
            </tbody>
          </motion.table>
        </motion.div>

        <motion.div 
          className="find-us-map"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.6 }}
        >
          <iframe
            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyDhy-GnS7ifGZdNT5jvl7lOhj25VgUAEpA&q=Rue+Saint-Jean+10+Nyon"
            width="100%"
            height="450"
            style={{ border: 0 }}
            loading="lazy"
            title={t("findUs.mapTitle")}
          ></iframe>
        </motion.div>
      </motion.div>
    </section>
  );
}

export default FindUsSection;
