import React from 'react';
import { Helmet } from 'react-helmet-async';

const SchemaMarkup: React.FC = () => {
  const localBusinessSchema = {
    "@context": "https://schema.org",
    "@type": "MedicalClinic",
    "name": "Cabinet Marenco",
    "image": "https://docmarenco.com/logo.jpg",
    "description": "Professional medical services in Nyon, Switzerland",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Rue Saint-Jean 10",
      "addressLocality": "Nyon",
      "addressRegion": "Vaud",
      "postalCode": "1260",
      "addressCountry": "CH"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": "46.3842401",
      "longitude": "6.2383174"
    },
    "telephone": "+41 22 990 25 50",
    "openingHoursSpecification": [
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": ["Monday", "Tuesday", "Wednesday"],
        "opens": "09:00",
        "closes": "12:00"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": ["Monday", "Tuesday", "Wednesday"],
        "opens": "13:20",
        "closes": "16:30"
      },
      {
        "@type": "OpeningHoursSpecification",
        "dayOfWeek": "Thursday",
        "opens": "09:00",
        "closes": "12:00"
      }
    ],
    "priceRange": "$$",
    "url": "https://docmarenco.com",
    "sameAs": [
      "https://docmarenco.com/en",
      "https://docmarenco.com/fr"
    ]
  };

  const healthcareServicesSchema = {
    "@context": "https://schema.org",
    "@type": "MedicalOrganization",
    "name": "Cabinet Marenco",
    "url": "https://docmarenco.com",
    "medicalSpecialty": [
      "GeneralPractice",
      "PreventiveMedicine",
      "InternalMedicine"
    ],
    "availableService": [
      {
        "@type": "MedicalProcedure",
        "name": "Blood Pressure Monitoring"
      },
      {
        "@type": "MedicalProcedure", 
        "name": "Eye Checks"
      },
      {
        "@type": "MedicalProcedure",
        "name": "Hypertension Management"
      }
    ]
  }
  
  const healthcareProfessionalSchema = {
    "@context": "https://schema.org",
    "@type": "Physician",
    "name": "Dr. Patrice Marenco",
    "medicalSpecialty": "GeneralPractice",
    "qualifications": [
      {
        "@type": "MedicalDegree",
        "name": "Medical Degree",
        "awardedBy": "Claude Bernard University"
      },
      {
        "@type": "MedicalDegree",
        "name": "Medical Degree",
        "awardedBy": "University of Aix-Marseille II"
      }
    ],
    "affiliation": {
      "@type": "MedicalOrganization",
      "name": "Cabinet Marenco",
      "url": "https://docmarenco.com"
    },
    "languages": ["French", "English"],
    "url": "https://docmarenco.com"
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(localBusinessSchema)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(healthcareServicesSchema)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(healthcareProfessionalSchema)}
      </script>
    </Helmet>
  );
};

export default SchemaMarkup;