import React from 'react';
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.scss'
import './il8n'
import { HelmetProvider } from 'react-helmet-async';

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
    },
    {
        path: "/en/*",
        element: <LanguageWrapper lang="en" />,
        children: [
            {
                path: "*",
                element: <App />
            }
        ]
    },
    {
        path: "/fr/*",
        element: <LanguageWrapper lang="fr" />,
        children: [
            {
                path: "*",
                element: <App />
            }
        ]
    }
]);

// Language wrapper component
function LanguageWrapper({ lang }: { lang: string }) {
    const { i18n } = useTranslation();
    
    useEffect(() => {
        i18n.changeLanguage(lang);
    }, [i18n, lang]);

    return <Outlet />;
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

// Create a helmetContext for react-helmet-async
const helmetContext = {};

root.render(
    <React.StrictMode>
        <HelmetProvider context={helmetContext}>
            <RouterProvider router={router} />
        </HelmetProvider>
    </React.StrictMode>
);

reportWebVitals();